<template>
  <div class="news">
    <NavBar />
    <div class="banner">
      <p class="banner-title">新闻资讯</p>
      <p class="banner-desc">关注众享金联最新动态</p>
    </div>
    <div class="content">
      <div class="content-title">公司新闻</div>
      <div class="news">
        <div class="news-left">
          <div class="left-item" v-for="(item, index) in newsList" :key="index">
            <div class="date">
              <div class="date-detail">
                <span class="date-day">{{
                  new Date(item.create_time).getDate()
                }}</span>
                <span class="line">/</span>
                <div class="date-year">
                  <span>{{ new Date(item.create_time).getMonth() + 1 }}月</span>
                  <span>{{ new Date(item.create_time).getFullYear() }}年</span>
                </div>
              </div>
            </div>
            <div class="desc" @click="handleNews(item)">
              <div class="desc-title">{{ item.title }}</div>
              <div class="desc-text">{{ item.content }}</div>
              <div class="more">
                <a :href="item.link" target="_blank">查看详情</a>
              </div>
            </div>
          </div>
          <el-pagination
            class="my-pagination"
            background
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageOptions.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pageOptions.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="pageOptions.total"
          >
          </el-pagination>
        </div>
        <div class="news-right">
          <div class="right-title">热门新闻</div>
          <div class="hot-news">
            <div
              class="hot-news-item"
              v-for="(item, index) in hotNewsList"
              :key="index"
              @click="handleHotNews(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import axios from "@/api/axios.js";
export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      newsList: [],
      pageOptions: {
        page: 1,
        size: 10,
        total: 0,
      },
      hotNewsList: [],
    };
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //请求新闻数据
    this.getNewsList();
    this.getHotNews();
  },
  methods: {
    getNewsList() {
      axios
        .get("/news/query", {
          params: {
            page: this.pageOptions.page,
            size: this.pageOptions.size,
          },
        })
        .then((res) => {
          this.newsList = res.data.payload.data;
          this.pageOptions.total = res.data.payload.counts;
        })
        .catch(() => {});
    },
    getHotNews() {
      axios
        .get("/news/queryHot")
        .then((res) => {
          this.hotNewsList = res.data.payload;
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.pageOptions.size = val;
      this.getNewsList();
    },
    handleCurrentChange(val) {
      this.pageOptions.page = val;
      this.getNewsList();
    },
    //热门新闻
    handleHotNews(item) {
      window.open(item.link, "_blank");
    },
    //新闻
    handleNews(item) {
      window.open(item.link, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
.news {
  background-color: #f8fafc;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .banner {
    margin-top: 64px;
    width: 100%;
    height: 476px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/news/bg.png") center/cover no-repeat;
    .banner-title {
      @extend .content-width;
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      color: #ffffff;
      line-height: 52px;
      padding-top: 188px;
    }
    .banner-desc {
      @extend .content-width;
      color: #ffffff;
      font-size: 18px;
      margin-top: 24px;
    }
  }

  .content {
    @extend .content-width;
    .content-title {
      padding: 70px 50px;
      font-size: 32px;
      font-family: "PingFangSC-Medium";
      color: #000000;
      line-height: 45px;
      text-align: center;
    }

    .news {
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;
      .news-left {
        width: 68.8%;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 0px 50px;
        .left-item {
          height: 230px;
          padding: 50px 0px;
          border-bottom: 1px solid #dbdbdb;
          display: flex;
          .date {
            width: 24%;
            padding: 30px 0px;
            .date-detail {
              display: flex;
              align-items: center;
              .date-day {
                font-size: 50px;
                font-weight: 600;
                color: #000000;
                line-height: 70px;
                margin-right: 4px;
              }
              .line {
                height: 56px;
                color: rgba(0, 0, 0, 0.45);
                line-height: 56px;
                font-size: 40px;
                margin-right: 6px;
              }
              .date-year {
                display: flex;
                flex-direction: column;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.45);
                line-height: 22px;
              }
            }
          }
          .desc {
            width: 76%;
            border-left: 1px solid #dbdbdb;
            position: relative;
            padding-left: 35px;
            .desc-title {
              font-size: 20px;
              font-family: "PingFangSC-Medium";
              font-weight: 500;
              color: #000000;
              line-height: 28px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 20px;
            }
            .desc-text {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.65);
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              margin: 16px 0px 12px;
            }
            .more {
              width: calc(100% - 35px);
              position: absolute;
              bottom: 0px;
              right: 0px;
              background: url("~@/assets/img/news/icon.png") right center/16px
                12px no-repeat;

              a {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
                line-height: 20px;
              }
            }
            &:hover {
              .desc-title,
              .more a {
                color: #0075ff;
              }
              .more {
                background: url("~@/assets/img/news/icon-active.png") right
                  center/16px 12px no-repeat;
              }
              cursor: pointer;
            }
          }
        }
        .el-pagination {
          margin: 36px 0px;
          display: flex;
          justify-content: flex-end;
        }
      }
      .news-right {
        width: 29%;

        .right-title {
          font-size: 26px;
          font-family: "PingFangSC-Medium";
          color: #000000;
          line-height: 37px;
          margin-bottom: 20px;
        }
        .hot-news {
          background: #ffffff;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          padding: 0px 20px 0px 24px;
          .hot-news-item {
            width: 100%;
            height: 52px;
            line-height: 52px;
            border-bottom: 1px solid #dbdbdb;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              color: #0075ff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
